body {
  font-family: 'Times New Roman', Times, serif;
}

.about-me {
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 2% 10%;
    height: 100vh;
  }

  .about-me h1 {
    color: #fff;
    font-size: 50px;
    align-self: center;
    /* margin-top: -200px; */
    /* padding: 2%; */
  }
  
  .about-me p {
    /* margin-top: 8px; */
    color: #fff;
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    /* width: 15cm; */
    /* margin-left: 5cm;
    margin-right: 5cm; */
    width:fit-content;
    text-align: justify;
    padding: 2%;
  }

  .about-me img {
    align-self: center;
    width: 250px;
    padding: 2%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    /* align-items: center; */
  }
  
  /* Create two equal columns that sits next to each other */
  .column {
    /* -ms-flex: 50%; */
    flex: 50%;
    /* padding: 0 4px; */
    /* padding-left: 1cm; */
    align-items: center;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    align-items: center;
    width: 250px;
  }