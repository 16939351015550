body {
    font-family: 'Times New Roman', Times, serif;
}

.interests {
    /* background: url('../../public/images/Image 1.JPEG') center center/cover no-repeat; */
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 5% 10%;
  }
  

  .roadmap {
    position: fixed;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    background-color: #333;
    padding: 10px;
    border-radius: 0 5px 5px 0;
  }
  
  .roadmap ul {
    list-style-type: none;
  }
  
  .roadmap li {
    margin: 10px 0;
    text-align: center;
  }
  
  .roadmap a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
  }
  
  .roadmap a:hover {
    color: #ddd;
  }
  
  .content {
    margin-left: 150px; /* Adjust margin to prevent content from overlapping with the roadmap */
    padding: 20px;
    color: white;
  }
  
  section {
    padding: 50px 0;
    border-bottom: 1px solid #ccc;
  }
  
  section h2 {
    margin-top: 0;
    color: white;
  }
  