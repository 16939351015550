body {
    font-family: 'Times New Roman', Times, serif;
  }

.skills {
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
}

.skills h1 {
    color: #fff;
    font-size: 50px;
    align-self: center;
    /* margin-top: -200px; */
    /* padding: 2%; */
  }

.skills ul {
  list-style: none;
}

.skills li {
  /* height: 20%; */
  padding: 2%;
  align-items: center;
  text-decoration: none;
}

.skills h2 {
  color: white;
  /* justify-content: center; */
  text-align: center;
}

.skills img {
  width: 50;
  height: 50;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5%;
}

/* .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; x
  padding: 20px; 
} */