body {
  font-family: 'Times New Roman', Times, serif;
}

.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  width: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  font-size: calc(1.2vw + 0.8vh);
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-icon {
  align-self: flex-start;
  height: 80px;
  display: flex;
  padding: 0vh 4vw;
}

.navbar-icon-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  align-self: center;
}

.navbar-icon-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-icon text {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 80px;
}
  
.navbar-menu {
  display: flex;
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
  
.navbar-menu li {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 80px;
}
  
  .navbar-menu li:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    /* display: none; */
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.2rem;
    background-color: transparent;
    color: #fff;
    padding: 0.5rem 1rem;
    /* border: 1px solid #fff; */
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .navbar-socials {
    flex: 1;
    justify-content: flex-end;
    height: 80px;
    display: flex;
    padding: 0vh 4vw;
    /* display: flex; */
    list-style: none;
  }

  .navbar-socials li {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 80px;
  }
  
  .navbar-socials a {
    color: #fff;
    font-size: 20px;
    width: 100%;
    align-self: center;
  }
  
  .social-icon:hover {
    color: #ddd;
  }
  
@media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }

    .navbar-icon {
      display: none;
      transition: all 0.5s ease;
    }

    .nav-socials {
      display: none;
      transition: all 0.5s ease;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: start;
    }
  
    .navbar-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .navbar-logo {
      position: absolute;
      top:0;
      left: 10;
    /* position: relative; */
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-item {
      /* text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      margin: 2rem auto; */
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 100%;
      height: fit-content;
      text-decoration: none;
      font-size: 1.2rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      transition: all 0.3s ease-out;
    }
    
    .nav-item:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
      transition: 250ms;
    }

    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.2rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }